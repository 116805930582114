/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2019-10-09
// Original author: Muhammad Omran
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.radivision-post {
  @media (min-width: 1025px) {
    max-width: 1200px;
  }

  @include media-breakpoint-down(md) {
    max-width: none;
    width: 400px;
    // margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    max-width: none;
    width: auto;
    // margin: 0 auto;
  }

  .modal-header {
    padding: 25px;
    border-bottom: none;

    p {
      margin: 0;
    }

    button.close {
      position: absolute;
      top: 5px;
      right: 40;
      z-index: 1;

      span svg {
        fill: white;
      }
    }
  }

  .modal-body {
    padding: 0px;
    margin-top: -1px;
  }

  .tab-container {
    @include media-breakpoint-up(xl) {
      max-height: 80vh;
      height: 80vh;
    }
    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
      margin:0px;
    }

    div.image-block {
      overflow-y:auto;
      display:flex;
      align-items: center;
      justify-content: center;
      height:100%;
      @include media-breakpoint-up(xl) {
        flex-basis:70%;
        .image-scroller {
          flex:1;
          padding:0px 40px;
          display:flex;
          align-items: flex-start;
          justify-content: center;
          max-height: 100%;
          height:100%;
          overflow-y: auto;
        }
      }
      flex-grow:1;
      .zoomed-in {
        cursor: zoom-out;
        img {
          max-height: auto;
        }
      }
      .zoomed-out {
        cursor: zoom-in;
        img {
          max-height: 100%;
        }
      }
    }

    & > div.text-block {
      flex: 1;
      padding: 24px 0;
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
      background-color:#fff;
      max-height: 100%;
      height:100%;
      overflow-y: auto;

      h3 {
        margin-bottom: 20px;
        margin-top: 20px;

        padding-left: 70px;
        padding-right: 70px;
        font-weight: bold;
      }

      div.radivision-content {
        position: relative;
        box-sizing: border-box;
        flex: 1;
        align-self: flex-start;
        width: 100%;
        @include media-breakpoint-up(xl) {
          p {
            padding:10px 30px;
            font-size: 18px;
          }
        }

        p {
          color:#000;
          width: 100%;
          overflow-wrap: break-word;
          font-size: 18px;
          text-align: left;
        }
      }

      .item_description {
        display: flex;
        padding-left: 70px;
        padding-right: 70px;
      }

      @media (width: 1024px) {
        h3 {
          font-size: 1.3rem;
          padding-left: 35px;
          padding-right: 35px;
        }

        div.radivision-content p {
          font-size: 18px;
          position: relative;
        }

        .item_description {
          padding-left: 35px;
          padding-right: 35px;
        }
      }

      @include media-breakpoint-down(md) {
        h3 {
          font-size: 1.3rem;
          padding-left: 35px;
          padding-right: 35px;
        }

        div.radivision-content p {
          font-size: 16px;
          position: relative;
        }

        .item_description {
          padding-left: 35px;
          padding-right: 35px;
        }
      }

      @include media-breakpoint-down(sm) {
        h6 {
          font-size: 0.85rem;
        }

        .item_description {
          padding-left: 0;
          padding-right: 35px;
        }
      }
    }
  }
}
