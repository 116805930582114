.confirmation-modal {
  &.show {
    background-color: rgba(0,0,0,0.4);

    .modal-dialog {
      width: 80%;
      max-width: 400px;
      margin: auto;
    }
  }

  .modal-actions {
    margin-top: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    .outline-light {
      border:0px;
      background-image:none;
      background-color: #313131;
    }
  }
  .modal-body {
    padding: 14px 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    span {
      flex: 1;
      font-size: 18px;
      font-weight: 800;
      color: white;
    }

    button {
      margin: 0;
      margin-left: 12px;
      padding: 8px 22px;
      font-size: 13px;
    }
  }

  .fat-title {
    .modal-body {
      display: block;
      text-align: right;
  
      span {
        width: 100%;
        display: block;
        text-align: center;
        padding: 8px 0 18px 0;
      }
    }
  }
}
