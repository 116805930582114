.media-image-section {
  margin: 35px 0 0 0;
  padding: 0;
  border-top: 0px solid #f2f4f4;
  text-align: left;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  div.close-btn {
    width: 28px;
    height: 28px;
    // background: #8b8b8b;
    border-radius: 50%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: absolute;
    right: 7px;
    top: 7px;
    line-height: 25px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      width: 24px;
      height: 24px;
      top: 13px;
      line-height: 21px;
    }

    svg {
      // width: 10px;
      fill: #8b8b8b;

      @include media-breakpoint-down(sm) {
        width: 8px;
      }
    }
  }

  .space-divider {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      flex-direction: column;
    }
  }

  .btn-radivision {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 12px 20px;
    }
  }

  .img-header {
    background-image: none;
    border: 1px solid #2e2e2e;
    text-transform: capitalize;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
    font-weight: 700;
    background-color: #2e2e2e;
    border-radius: 22px;
    font-family: "Muli";
    color: white;

    @media (min-width:2000px) {
      font-size: 18px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }
  }

  canvas {
    max-width: 100%;
    cursor: grab;
    touch-action: none;
    // width: 100% !important;
    height: inherit !important;
    border: 1px solid #161616;

    &.selected {
      border: 2px solid #4cb4e7;
    }

  }

  .img-container {
    overflow: hidden;

    img {
      max-height: 240px;
      border: 2px solid $radivision-lt-gray;
    }
  }

  p {
    font-weight: 600;
    text-align: center;
  }

  .image-upload-header {
    hr {
      width: 35%;
      margin: 6px;
      display: inline-block;

      @include media-breakpoint-down(sm) {
        width: 29%;
      }

      @include media-breakpoint-down(sm) {
        width: 33%;
      }
    }
  }

  input[type="range"] {
    margin-bottom: 25px !important;
    margin-top: 15px;
    display: inline-block;
    width: 65%;
    vertical-align: sub;

    @media (min-width:2000px) {
      margin-bottom: 35px !important;
      margin-top: 20px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 15px !important;
    }

    @include media-breakpoint-down(sm) {
      width: 59%;
    }
  }

  // .col-form-label {
  //   font-weight: 900;
  //   text-transform: uppercase;
  //   letter-spacing: 0.075rem;
  //   font-size: 12px;
  //   // letter-spacing: 0.3px;
  //   width: 35%;

  //   @media (min-width: 2000px) {
  //     font-size: 22px;
  //   }

  //   @include media-breakpoint-down(sm) {
  //     width: 41%;
  //     font-size: 11px;
  //   }
  // }

  .custom-file {
    margin-bottom: 32px;
    display: block !important;

    @media (min-width:2000px) {
      margin-bottom: 65px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 10px !important;
    }
  }

  .custom-file-label {
    padding: 10px 10px;
    text-align: center;
    width: 100%;
    height: 43px;
    cursor: pointer;

    @media (min-width:2000px) {
      padding: 20px;
    }

    &:after {
      display: none;
    }
  }

  .custom-file-input {
    display: none;
  }

  .form-group {
    .col-sm-6 {
      &:first-child {
        text-align: center;
        position: relative;

        p.alert {
          position: absolute;
          width: calc(100% - 2.5rem);
        }
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }

  .modal-footer {
    .col-sm-6 {
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }
}

#editProfileModal {
  .media-image-section {
    margin: 0;
    padding: 0;
    border-top: 0px solid #f2f4f4;
    text-align: left;

    .custom-range {
      margin-bottom: 15px;
    }

    .btn-radivision {
      margin-top: 0;

      // @media (min-width: 2000px) {
      //   margin-top: 28px;
      // }
    }
  }

  .header .modal form {
    .basic-info {
      label {
        width: 100%;
        margin-top: 12px;
      }

      input {
        border: 1px solid #ced4da;
      }
    }
  }

  .btn-radivision {
    width: 100%;
  }
}

.add-image-btn:not(.custom-file) {
  width: 100%;
  // background-color: #f0f0f0 !important;
  // background-image: none;
  // border-color: #d1d1db !important;
  // color: #32323b !important;
  font-family: "Muli";
  color: #fff;
  text-align: center;
  font-size: 15px !important;
  font-weight: 700 !important;
  padding: 0 20px !important;
  text-transform: capitalize !important;
  height: 43px;

}

.no-padding {
  padding: 0;

  @media (min-width: 2000px) {
    margin: 20px 0;
  }
}

.form-group>.col,
.form-group>[class*="col-"] {
  display: inline-block;

  .image-link {
    margin-bottom: 0;
  }
}

.addImageButton {
  vertical-align: bottom;
  margin-right: 10px;
  height: 20px;
  fill: white;

  @include media-breakpoint-down(sm) {
    height: 20px;
  }
}

.revertDefault {
  margin-top: 20px !important;

  @media (min-width:2000px) {
    margin-top: 35px !important;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0px !important;
  }
}

.rangControls {
  div {
    width: calc(100% / 2);
    display: inline-block;

    label {
      font-family: Muli;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      width: 100%;
    }

    input[type=range] {
      margin-bottom: 0 !important;
      margin-top: 0;
      display: inline-block;
      width: 90%;
      vertical-align: middle;
    }
  }
}

.custom-file-label {
  background-color: #4cb4e7 !important;
  border-radius: 29px;
  color: white;
  border: 0;
}
input:disabled + .custom-file-label {
  opacity: 0.62;
  cursor: not-allowed;
}