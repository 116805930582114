/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-10-09
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.error-modal {
    p {
        font-size: 15pt;
    }

    button {
        font-size: 11pt;
    }
}