/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-06-20
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.modal-dialog.modal-dialog-centered {
  @media (min-width: 2500px) {
    max-width: 1200px;
  }
}

////////////////// Modal header ///////////////////////////////
div .modal-header {
  padding: 10px 35px;
  border-bottom: 0;

  @media (min-width: 2500px) {
    padding: 1.5rem 3rem;
  }

  p {
    font-family: 'Muli';
    font-size: 30px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
  }
}

.radivisionLogo {
  width: 28%;
}

.modal-header span svg {
  fill: $snow-white;
  margin: auto;

  width: 24px;
  height: 24px;
}

///////////////// Modal body ///////////////////////////////
.modal-body {
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(md) {
    padding: 15px;
  }

  h1 {
    font-family: 'Muli';
    font-size: 30px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    text-align: center;

    @media (min-width: 2500px) {
      font-size: 40px;
    }
  }

  p,
  h5 {
    font-family: 'Muli';
    font-size: 14px;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    margin-bottom: 0;

    @media (min-width: 2500px) {
      font-size: 20px;
    }
  }
}

.modal-dialog {
  @include media-breakpoint-down(md) {
    max-width: 650px;
  }

}

.modal-backdrop.show {
  opacity: 0.7;
  z-index: 5 !important;
}

.modal {
  background-color: rgba(0,0,0,0.8);
  .modal-dialog {
    transition: all 0.3s ease-out;
    transform: translateY(-20px);
    opacity:0.5;
  }
  &.show{
    .modal-dialog {
      transform: translateY(0px);
      opacity:1;
      margin:0px auto!important;
    }
  }

  .modal-content {
    background: rgba(22, 22, 22, 1);
    border-radius: 30px;
    box-shadow: 0px 6px 35px 0px rgba(22, 22, 22, 0.33);
   overflow: hidden;

  }

  .svgBackground {
    opacity: .03;
    position: absolute;


    .st0 {
      fill: #A8AAAC;
    }

    .st1 {
      fill: #4FB4E6;
    }

    .st2 {
      fill: #FFFFFF;
    }

    &:first-child {
      width: 275px;
      right: -15%;
      top: 3%;
    }

    &:last-child {
      width: 300px;
      left: -12%;
      bottom: -7%;
    }
  }
}

////////////Modal footer /////////////////////////
.modal-footer {
  display: block;
  text-align: center;
  border-top: 0;
}

.has-float-label {
  input::placeholder {
    font-size: 15pt;
    font-weight: 500;
  }

  input {
    height: 55px;

    @media (min-width: 2000px) {
      height: 80px;
      padding: 1rem 1.4rem;
    }
  }

  span {
    font-size: 13pt;

    @media (min-width: 2000px) {
      font-size: 16pt !important;
    }
  }

  .form-control:placeholder-shown:not(:focus)+* {
    font-size: 11pt;
    font-weight: 500;
    opacity: 1;
    top: 1.25rem;
  }

  .has-float-label label,
  .has-float-label>span {
    top: -.15rem;
  }

  #change-password-form .has-float-label .form-control:placeholder-shown:not(:focus)+* {
    font-size: 11pt;
    font-weight: 500;
    opacity: 1;
    top: 1.5rem;
  }
}

.modal-header .close {
  padding: 0;
  margin: 10px -10px 0px 0px;
  width: 32px;
  height: 32px;
  position: absolute;
  top:0px;
  right:30px;
  span {
    display: block;
    width: 32px;
    height: 32px;
  }
  &.custom-icon {
    right: unset;
    left:50px;
    span {
      width: 32px!important;
      height: 32px!important;
      svg {
        width: 32px!important;
        height: 32px!important;
      }
    }
  }
}

.modal.fade.show {
  padding-right: 0 !important;
  background: rgba(0, 0, 0, 0.9);
}

#announcement-modal {
  @include media-breakpoint-up(lg) {
    .modal-dialog.modal-dialog-centered {
      width: 600px;
    }
  }
  button.close {
    opacity: 1;
    i {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      svg {
        fill: white;
        width: 20px;
        height: 20px;
      }
    }
  }
}
