$padding-horizontal: 59px;

.details-panel {
  background-color: rgba(6, 6, 6, 0.9);
  transition: 0.6s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-fill-available;
  padding: 100px 0;
  z-index: 999;
  color: #fff;
  font-family: "Muli";
  font-size: 14px;
  line-height: 18px;
  overflow-x: hidden;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  .details-panel-backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .details-panel-wrapper {
    width: 77%;
    max-width: 1058px;
    position: relative;
    background-color: $dark-mode-bg;
    margin: 0 auto;
    height: max-content;

    & > i {
      position: absolute;
      right: -47px;
      top: 0;
      width: 36px;
      height: 36px;
      background-color: transparent;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;

      svg {
        width: 36px;
        height: 36px;
        vertical-align: unset;
        fill: white;
      }

      @include media-breakpoint-down(sm) {
        right: -42px;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;

      & > i {
        right: 18px;
        top: 18px;
        padding: 0;
        z-index: 1;
        width: 35px;
        height: 35px;
        background-color: #fff;
        border: 1px solid $dark-mode-bg;

        svg {
          width: 35px;
          height: 35px;
          fill: $dark-mode-bg;
          border-radius: 50%;
          margin-left: -1px;
          margin-top: -1px;
        }
      }
    }

    .details-panel-container {
      display: flex;
      flex-direction: column;
      max-width: 100%;

      .details-panel-video {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: 47px;

        &::before {
          display: block;
          content: "";
          padding-top: calc(100% / (16 / 9));
        }

        .video-panel {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }

        video {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }

        img.details-panel-cover {
          width: auto;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
        }
      }
      .select-pitch-version {
        margin-top: -30px;
        margin-bottom: 30px;
      }

      .details-panel-section {
        padding: 0 $padding-horizontal;
        margin-bottom: 80px;

        &.section-no-padding {
          padding: 0;

          .episodes-seasons {
            display: flex;
            justify-content: space-between;
          }

          .season-select {
            background-color: transparent;
            color: white;
            padding: 10px 20px;
            margin: 0px 59px 30px 59px;
            border-radius: 10px;
            cursor: pointer;

            .select-option {
              background-color: rgba(6, 6, 6, 0.9);
              color: white;
              cursor: pointer;
            }
          }

          .section-title {
            padding: 0 $padding-horizontal;
          }
        }

        .section-title {
          font-size: 24px;
          font-family: "MuliBold";
          line-height: 36px;
          margin-bottom: 15px;
        }

        @include media-breakpoint-down(md) {
          padding: 0 35px;
          margin-bottom: 50px;

          &.section-no-padding {
            padding: 0;

            .section-title {
              padding: 0 35px;
            }
          }
        }
      }

      .details-panel-info {
        .details-panel-section {
          &.details-panel-meta {
            margin-bottom: 67px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .details-panel-description {
              flex: 1;
              margin-right: 80px;
              max-width: 100%;

              .item-actions {
                margin-bottom: 36px;
                display: flex;
                align-items: center;

                span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 7px 24px;
                  background-color: #525252;
                  margin-right: 10px;
                  cursor: pointer;
                  border-radius: 17px;

                  svg {
                    height: calc(20px * 0.75);
                    width: calc(20px * 0.75);
                    fill: #fff;
                  }

                  &.saved {
                    background-color: #fff;

                    svg {
                      fill: $radivision-blue;
                    }
                  }

                  @include media-breakpoint-down(md) {
                    padding: 7px;
                    border-radius: 50%;
                  }
                }
              }

              h1.video-title {
                font-size: 28px;
                font-family: "MuliExtraBold";
                line-height: 36px;
              }

              .video-description {
                font-size: 16px;
                color: #ccc;
              }
            }

            .details-panel-show-logo {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;

              .radivision-logo {
                height: 27px;
                width: auto;
                margin-bottom: 7px;
              }

              .show-logo-img {
                width: 100%;
                height: auto;
                max-width: 246px;
              }
            }

            @include media-breakpoint-down(md) {
              margin-bottom: 48px;
              flex-direction: column-reverse;
              align-items: flex-start;

              .details-panel-show-logo {
                align-items: flex-start;
                margin-bottom: 32px;
              }

              .details-panel-description {
                margin-right: 0px;

                h1.video-title {
                  font-size: 28px;
                }
              }
            }

            @include media-breakpoint-down(sm) {
              margin-bottom: 20px;
              .details-panel-description {
                h1.video-title {
                  font-size: 22px;
                  line-height: 28px;
                }
              }
            }
          }

          &.detail-panel-cast {
            .episode-cast {
              display: flex;
              width: 100%;
              align-items: flex-start;

              .cast-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;
                &.multi {
                  flex-direction: column;
                  justify-content: center;
                }
                @include media-breakpoint-down(sm) {
                  flex-direction: column;
                }

                .cast-multi {
                  display: flex;
                  padding: 0px 8px;
                  width: 100%;
                  flex-direction: row;
                }

                .cast-item-avatars {
                  position: relative;
                  width: 111px;
                  height: 111px;
                  margin-right: 24px;
                  margin-bottom: 8px;
                  &.multi {
                    margin-right: 0px;
                  }

                  a {
                    display: block;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
                    background-color: #212121;
                    color: #fff;
                    text-decoration: none;

                    &.cast-person {
                      width: 111px;
                      height: 111px;
                    }

                    &.cast-company {
                      bottom: 0;
                      right: -15px;
                      width: 111px;
                      height: 111px;
                      border: 2px solid $dark-mode-bg;
                      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
                      background-color: #aaa;
                    }

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                }

                .cast-item-info {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;
                  margin-right: 35px;
                  &.multi {
                    margin-right: 0px;
                    align-items: left;
                    margin-bottom: 2px;
                    margin-left: 12px;
                  }

                  a {
                    color: #fff;
                    text-decoration: none;
                  }
                  h4 {
                    font-size: 18px;
                    font-family: "MuliBold";
                    margin-bottom: 4px;
                  }

                  h5 {
                    font-size: 16px;
                    margin-bottom: 4px;
                  }

                  p {
                    margin: 0;
                    color: #ccc;
                  }
                }
              }
            }

            @include media-breakpoint-down(md) {
              .episode-cast {
                flex-direction: column;
                align-items: flex-start;

                .cast-item {
                  max-width: 100%;
                  margin-bottom: 28px;

                  .cast-item-info {
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
      }

      .all-episodes {
        .show-episodes {
          display: flex;
          flex-direction: column;

          .details-panel-episode {
            padding: 21px 60px;
            margin-bottom: 2px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: $dark-mode-lighter;

            @include media-breakpoint-down(sm) {
              padding: 21px 24px;
            }

            &:not(.current-episode) {
              cursor: pointer;
            }

            &.current-episode {
              padding-left: 0;
              background-color: $dark-mode-much-lighter;

              &::before {
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                background-color: $radivision-blue;
                border-radius: 50%;
                margin: 0 25px;

                @include media-breakpoint-down(sm) {
                  margin: 0 11.5px;
                }
              }
            }

            .episode-number {
              font-size: 26px;
              font-family: "MuliBold";
              width: 32px;
              flex: 0 0 32px;
              text-align: center;
              overflow: visible;

              @include media-breakpoint-down(sm) {
                font-size: 20px;
                width: 24px;
                flex: 0 0 24px;
              }
            }

            .episode-poster {
              margin: 0 34px;
              border-radius: 16px;
              box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);
              width: 188px;
              flex: 0 0 188px;
              position: relative;
              overflow: hidden;

              @include media-breakpoint-down(sm) {
                margin: 0 24px;
              }

              @media (max-width: 386px) {
                margin: 0 18px;
              }

              &:before {
                display: block;
                content: "";
                padding-top: calc(100% / (16 / 9));
              }

              img {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
              }
            }

            .episode-meta {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;

              @include media-breakpoint-down(sm) {
                display: none;
              }

              h4.episode-meta-title {
                margin-bottom: 4px;
                line-height: 22px;
                font-family: "MuliBold";
                font-size: 18px;

                @include media-breakpoint-down(md) {
                  display: none;
                }
              }

              .episode-meta-description {
                font-size: 14px;
                color: #ccc;
                max-height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;

                @include media-breakpoint-down(md) {
                  display: none;
                }
              }
            }

            .episode-play-btn {
              position: relative;
              flex: 0 0 80px;
              margin-left: 60px;
              display: flex;
              align-items: center;
              justify-content: center;

              @include media-breakpoint-down(sm) {
                margin-left: 16px;
              }

              @media (max-width: 386px) {
                margin-left: 0px;
              }

              @media (max-width: 386px) {
                margin-left: 0px;
              }

              @media (max-width: 340px) {
                display: none;
              }

              i.play-icon {
                width: 40px;
                height: 40px;
                fill: $radivision-blue;
                border-radius: 50%;
                background-image: radial-gradient(white 70%, transparent 70%);
              }

              span.now-playing {
                text-transform: uppercase;
                font-size: 18px;
                font-family: "MuliExtraBold";
                color: #fff;
                text-align: center;

                span {
                  display: block;

                  &:first-child {
                    margin-bottom: 6px;
                  }
                }

                @media (max-width: 356px) {
                  display: none;
                }
              }

              .episode-duration {
                position: absolute;
                bottom: -33px;
                font-size: 16px;
                font-family: "Muli";
                color: #aaa;
              }
            }
          }
        }
      }

      .more-like-section {
        .carousel-header {
          display: none;
        }

        .more-like-this {
          .grid-view {
            .grid-item {
              .grid-details {
                .card-title {
                  margin-bottom: 0px;
                  font-family: "Muli";
                }
                .card-description {
                  font-size: 14px;
                  font-family: "Muli";
                }
              }
            }
          }
        }
      }
    }
  }
}

.pitch-people-entity {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 50px;
  box-sizing: border-box;
  justify-content: space-between;
  h4 {
    font-family: MuliBold;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .avatars-names {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .people-entity-section {
    width: 40%;
    display: flex;
    flex-flow: column nowrap;
    min-width: 360px;
  }
  .names {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        color: $radivision-blue;
      }
    }
    h2 {
      font-family: MuliBold;
      font-size: 18px;
    }
    h5 {
      font-family: MuliSemiBold;
      font-size: 16px;
    }
    h6 {
      font-family: Muli;
      font-size: 14px;
      color: #aaaaaa;
    }
  }
  .avatars {
    width: 111px;
    height: 111px;
    margin-right: 20px;
    position: relative;
    .large-avatar {
      display: flex;
      width: 111px;
      height: 111px;
      border-radius: 60px;
      overflow: hidden;
      background-color: #999999;
      background-size: cover;
      background-position: center center;
    }
    .small-avatar {
      display: flex;
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background-color: #999999;
      border: 2px solid #212121;
      background-size: cover;
      background-position: center center;
    }
  }
}
.details-panel {
  .pitch-card-actions {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    flex-flow: row;
    .btn-radivision {
      font-size: 15px;
      display: flex;
      padding: 7px 20px;
      svg {
        fill: white;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        margin-top: 4px;
      }
    }
    .pitch-card-button {
      cursor: pointer;
      text-decoration: none;
      margin-right: 10px;
      background-color: #19acf7;
      font-size: 16px;
      color: white;
      height: 36px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 20px;
      white-space: nowrap;
      &.is-grey {
        border: 2px solid grey;
        color: grey;
      }
      &.outline-button {
        background-color: transparent;
        border: 2px solid #ffffff;
      }
      &.disabled {
        background-color: grey;
        color: #bbbbbb;
        cursor: not-allowed;
      }
      svg {
        width: 16px;
        height: 16px;
        fill: white;
        margin-left: 10px;
      }
    }
  }
  img.pitch-planet-logo {
    max-width: 200px;
  }
}
