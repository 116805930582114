/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-06-26
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.createAccount {
  form label {
    background-color: transparent;
    font-family: 'Muli';
    font-size: 15px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0.5rem;

    @media (min-width: 2500px) {
      font-size: 20px;
    }
  }

  &.claim-profile {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>div.row {
        width: 100%;

        &>div {
          padding-left: 0;
          padding-right: 0;
        }

        @media (min-width: 2500px) {
          width: 100%;
          font-size: 1.6rem;
        }
      }

      div.col-md-6,
      div.col-md-12 {
        padding-right: 0;
      }

      div.checkSection {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;

        input {
          margin-right: 15px;
        }
      }

      label {
        margin-bottom: 0.5rem;
        padding-right: 0;
      }

      .documents-list {
        p {
          display: flex;
          align-items: center;
          margin: 8px;
          justify-content: space-between;

          span.rm-doc {
            font-size: 8px;
            line-height: 8px;
            cursor: pointer;

            svg {
              width: 16px;
              height: 16px;
              fill: $gray;
              transition: all 0.3s;
            }

            &:hover {
              svg {
                fill: #dc3545;
              }
            }
          }
        }
      }
    }
  }

  .newUX {
    align-items: center;
    justify-content: center;
    flex-direction: column;
@media (min-width:2000px) {
  padding:0 90px
}
    /////////////////////////////////// social media login /////////////////////////
    .socialmediaLogin {
      margin: 60px 0 30px;

      div.social-login-btn {
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 22px;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }

      a {
        height: 43px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          font-family: 'Muli';
          font-size: 15px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
          width:170px;
          white-space: nowrap;

          @media (min-width: 2500px) {
            font-size: 18px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
          margin-right: 20px;

          @media (min-width: 2000px) {
            width: 26px;
            height: 26px;
          }
        }
      }

      .emailIcon {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 1);
      }

      .facebookIcon {
        background-color: rgba(24, 119, 242, 1);
      }

      .googleIcon {
        background-color: rgba(66, 133, 244, 1);
      }

      .linkedinIcon {
        background-color: rgba(40, 103, 178, 1);
      }

      .twitterIcon {
        background-color: rgba(29, 161, 242, 1);
      }
    }

    .infoLogin {
      margin: 60px 0 30px;

      .verificationCode {
        background: transparent;
        margin-bottom: 25px;

        input {
          height: 68px;
          width: 68px !important;
          border-radius: 50%;
          margin-right: 5px;
        }

        span {
          display: none;
        }
      }

      input {
        border: 1px solid #2e2e2e;
        background-color: #2e2e2e;
        border-radius: 22px;
        font-family: 'Muli';
        font-size: 13px;
        color: rgba(255, 255, 255, 1);
        height: 43px;
        padding: 0px 22px;

        &:focus {
          &~span {
            color: $radivision-blue;
            background: #fff;
            top: -7px;
          }
        }

      }

      .was-validated .form-control:invalid,
      .form-control.is-invalid {
        border-color: #dc3545 !important;
      }

      .has-float-label {
        .form-control {
          &:placeholder-shown {
            &:not(:focus)+* {
              top: 16px;
            }
          }

          &::placeholder {
            font-size: 14px !important;
          }
        }

        >span {
          left: 0.5rem;
          font-size: 11pt;
          background-color: #fff;
          padding: 0 5px;
        }
      }

      .required-star {
        color: red;
        position: relative;
        top: 1px;
        left: 1px;
      }

      .password-trigger {
        position: relative;
        bottom: 0.5rem;
        left: 17.5rem;
        cursor: pointer;
        color: #b4b4b4;
        background-color: white;
      }

      .Re-enter-password-field {
        margin-top: -24px;
        margin-bottom: -22px;
      }

      .re-enter-password-trigger {
        position: relative;
        bottom: 0.5rem;
        left: 17.5rem;
        cursor: pointer;
        color: #b4b4b4;
        background-color: white;
      }

      .checkSection {
        margin-bottom: 0rem;
      }

      .checkSection input {
        height: 1rem;
        width: 1rem;
        margin-right: 10px;
      }

      .checkSection h5 {
        display: inline-block;
        width: 80%;
        vertical-align: middle;
        font-size: 1rem;

        a {
          color: $radivision-blue;
        }
      }

      button {
        width: 100%;
        font-family: 'Muli';
        font-size: 15px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        padding: 9.5px 30px;
        text-transform: capitalize;
      }

      h6 {
        font-family: 'Muli';
        font-size: 11px;
        color: rgba(129, 129, 129, 1);
        text-align: center;
        padding: 15px 40px;
        margin-bottom: 0;
        padding-bottom: 0;

        @media (min-width: 2000px) {
          font-size: 16px;
        }

        a {
          color: rgba(97, 158, 241, 1);
        }
      }

      .alert {
        h6 {
          padding: 0;
          font-size: 14px;
        }
      }

      h3 {
        text-align: left;
        line-height: 0;
        margin-bottom: 15px;

        a {
          font-family: "Muli";
          font-size: 13px;
          color: white;
        }
      }
    }
  }

  .upload-evedince {
    margin-bottom: 1.5rem;

    .doc-upload-preview {
      position: relative;
      width: 100%;
      border: 1px solid #ced4da;

      &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 100%;
      }

      canvas {
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0px;
        left: 0px;
      }
    }

    input {
      display: none;
    }

    label {
      padding: 14px 30px;
      width: 100% !important;
      margin-bottom: 0.5rem !important;
      background-color: #f0f0f0 !important;
      background-image: none;
      border-color: #d1d1db !important;
      color: #32323b !important;

      svg {
        fill: black;
      }
    }

    label:after {
      content: none;
    }

    .instructions {
      font-size: 14px;

      p {
        margin-bottom: 0;
        font-weight: bold;
        color: #32323b;
        text-align: left;
        color: rgba(255,255,255,0.85);
      }

      ul {
        padding-left: 15px;
        color: rgba(255,255,255,0.85);
        font-weight: 600;
        margin-bottom: 30px;
      }
    }
  }
}

.createAccountFooter {
  h4 {
    font-family: 'Muli';
    font-size: 13px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    display: inline-block;

    @media (min-width: 2000px) {
      font-size: 16px;
    }
  }

  a {
    font-family: 'Muli';
    font-size: 13px;
    color: rgba(231, 76, 103, 1);
    text-align: center;
    margin-left: 10px;

    @media (min-width: 2000px) {
      font-size: 14px;
    }
  }
}

#claim-profile-form {

  .form-control:disabled,
  .form-control[readonly] {
    cursor: not-allowed;
    border: 1px solid #2e2e2e;
    background-color: #2e2e2e;
    border-radius: 22px;
    font-family: Muli;
    font-size: 13px;
    color: rgba(255,255,255,0.45);
    height: 43px;
  }
}
