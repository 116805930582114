.add-media-modal,
.form-modal {
  .form-group {
    margin-bottom: 20px;

    label {
      background-color: transparent;
      font-family: Muli;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      text-align: left;
    }

    input {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      border-radius: 22px;
      font-family: Muli;
      font-size: 13px;
      color: #fff;
      height: 43px;
      position: relative;

      &.warn {
        border-color: $danger;
      }

      &:disabled {
        background-color: $dark-mode-black;
        border-color: $dark-mode-black;
      }
    }

    textarea {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      border-radius: 10px;
      font-family: Muli;
      font-size: 13px;
      color: #fff;
      position: relative;
      padding-right: 35px;
    }

    button:not(.custom-file-input) {
      font-family: "Muli";
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      padding: 0 20px;
      text-transform: capitalize;
      height: 43px;
      margin: 0;

      &.auto-fill {
        position: absolute;
        right: 15px;
        top: 30px;
        height: 43px;
        margin: 0;
        min-width: 100.43px;
      }

      .spinner-border {
        margin-right: 2px;
        width: 1rem;
        height: 1rem;
      }
    }

    .last-item {
      padding-bottom: 20px;
      margin-bottom: 40px;
    }

    .media-image-section {
      padding: 35px 0 0 0;
      // border-top: 2px solid #f2f4f4;
      padding: 0;

      .form-group {
        .col-sm-6 {
          button {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }

      .col-sm-7.col-lg-6 {
        div {
          padding: 0;
        }
      }

      .col-form-label {
        padding-top: calc(1rem + 1px);
      }
    }
  }

  .modal-content {
    overflow: unset;

    .svgBackground {
      display: none;
    }
  }

  .modal-footer {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    padding: 0;

    .pl0 {
      padding-left: 0;
    }

    .pr0 {
      padding-right: 0;
    }

    button {
      width: 100%;
      color: #fff;
      text-transform: capitalize;
      padding: 10px 30px;
      margin: 0;
      font-size: 15px;
      font-weight: 700;
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        margin-top: 16px;
        padding: 10px 10px;
      }
    }
  }

  .invalid-feedback.main {
    background-color: #dc35456b;
    padding: 10px;
    border: 1px solid;
  }

  .add-entity {
    .search-entity-title {
      font-weight: 700;
      padding-left: 35px;
      padding-right: 35px;
      color: white;
    }

    .search-form.search-form-shown {
      right: 0;
      width: 100%;
      top: auto;
      position: relative;
      z-index: 1;
      margin: 25px auto;
      padding-bottom: 0;

      hr {
        width: 100%;
        float: none;
      }

      input {
        font-size: 20px;
        border: 1px solid transparent;
        transition: all 0.3s;
        position: relative;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }

      &.required input {
        border-color: #dc3545;
      }

      .container-fluid {
        padding: 0 35px;
      }
    }

    .add-company {
      margin: auto;
      display: inline-block;
      padding: 0 0 50px 0;
      position: relative;
      background-color: transparent;
      text-align: center;

      .btn {
        height: 50px;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        svg {
          background-color: #8b8b8b;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          fill: rgba(255, 255, 255, 1);
          padding: 10px;

          @include media-breakpoint-down(md) {
            width: 32px;
            height: 32px;
          }
        }

        p {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          padding-left: 10px;
          margin: 0;
          display: inline-block;
          color: rgba(255, 255, 255, 0.5);

          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .modal-content {
    .modal-body {
      .tabbed-nav {
        background-color: transparent;
        padding: 0;

        .nav.nav-tabs.panel-nav,
        .nav.dropdown {
          margin-top: 32px;

          @media (max-width: 997px) {
            margin-top: 10px;
            width: calc(100% - 15px);
            overflow-x: auto;
            box-sizing: content-box;
            z-index: 1000;
            white-space: nowrap;
            display: block;
            overflow-y: hidden;
          }
        }

        .nav.nav-tabs.panel-nav,
        .nav.dropdown {
          margin-top: 0;
          scroll-margin: 10px;
          margin-bottom: 30px;
        }

        p {
          color: #32323b;
        }

        .nav-link {
          font-family: "Muli";
          font-size: 18px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          background: transparent;
          border-radius: 0;
          text-transform: capitalize !important;

          &.active {
            color: white;
            background-color: transparent;
            border-bottom: 3px solid #4cb4e7;
          }
        }
      }

      .panel-nav {
        margin-bottom: 10px;
        position: relative;
        border-bottom: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        .nav-items {
          max-width: 80%;

          .nav-item {
            display: inline-block;
          }
        }

        .nav-item {
          display: inline-block;

          .nav-link {
            font-family: "Muli";
            font-size: 18px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            background: transparent;
            border-radius: 0;
            text-transform: capitalize !important;

            &.active {
              color: white;
              background-color: transparent;
            }
          }
        }
      }

      .tab-content {
        > .active {
          padding: 0px;
        }
      }

      .content {
        .title {
          margin-bottom: 30px;
          font-size: 1.2rem;
          font-weight: 500;

          svg {
            height: 24px;
            margin-right: 5px;
            vertical-align: sub;
            fill: $radivision-blue;
          }

          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }
        }

        .form-control {
          &:focus {
            box-shadow: none;
          }

          &:valid ~ .invalid-feedback {
            display: none;
          }
        }

        .required {
          color: #dc3545;
        }

        .has-float-label {
          width: 100%;
          // margin: 0;
          top: 0;
          background: transparent;

          span {
            font-size: 14px;
            font-weight: 600;
          }
        }

        .modal-footer {
          .add-media-btn {
            color: $snow-white;
          }

          .btn-radivision.outline {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }

      .modal-footer {
        .submit-btn {
          background-color: $radivision-blue;
          border-color: $radivision-blue;
          // letter-spacing: 0.8px;
          padding: 12px 64px;
          font-size: 15px;
          font-weight: 700;
          cursor: pointer;
        }

        .revert-btn {
          margin-top: 0;
          padding: 12px 68px;
          font-weight: 700;
          font-size: 15px;
          margin-bottom: 10px;
        }
      }

      .modal-body .modal-footer {
        display: block;
        // margin-top: 20px;
        padding: 0;
      }

      .or {
        position: relative !important;
        background-color: transparent !important;
        right: auto !important;
        left: auto !important;
        text-align: center;
        margin-top: -15px;
        margin-bottom: 10px;

        span {
          display: inline-block;
          background-color: #161616;
          padding: 0px 15px;
          color: white;

          @media (min-width: 2000px) {
            font-size: 26px;
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: #2e2e2e;
          left: 50%;
          top: 11px;
          z-index: -1;
          transform: translateX(-50%);

          @media (min-width: 2000px) {
            top: 20px;
          }
        }
      }

      .results-container hr {
        display: none;
      }

      .add-entity {
        margin: 0px -40px;

        .add-company-padd {
          padding: 0px 40px;

          .col-sm-2 {
            padding-right: 15px;
            padding-left: 0;

            @media (min-width: 1537px) {
              padding-right: 15px;
              padding-left: 0;
            }

            @include media-breakpoint-down(xs) {
              padding-left: 0px;
            }
          }

          .alert {
            text-align: center;
            font-weight: bold;
            font-size: 1rem;

            .invalid-feedback {
              font-size: 100%;

              span {
                background: transparent;
              }
            }

            a {
              color: #4cb4e7;
              margin-left: 10px;
            }
          }
        }

        @include media-breakpoint-down(xs) {
          padding: 0px 15px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .col-10 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .results-container hr {
      display: none;
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    /* Hides the slider so that custom slider can be made */
    width: 100%;
    /* Specific width is required for Firefox. */
    background: transparent;
    /* Otherwise white in Chrome */
    margin-bottom: 20px;
    margin-top: 15px;
    display: inline-block;
    width: 65%;
    vertical-align: sub;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none;
    /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: $radivision-blue;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-top: -12px;
    /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    cursor: pointer;
  }

  /* All the same stuff for Firefox */

  input[type="range"]::-moz-range-thumb {
    background: $radivision-blue;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  }

  /* All the same stuff for IE */

  input[type="range"]::-ms-thumb {
    background: $radivision-blue;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  @include media-breakpoint-down(sm) {
    .col-10 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .nav-tabs .nav-item {
    width: calc(100% / 3);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    svg {
      width: 18px;
      margin-right: 10px;
      fill: white;
    }

    .articleVideo {
      width: 15px;
    }

    .onlineCourseIcon {
      width: 20px;
    }

    .podcast-svg {
      .st0 {
        fill: #ffffff;
      }

      .st1 {
        fill: #4cb4e7;
      }
    }
  }

  div .modal-header {
    padding: 25px 40px;
    padding-bottom: 0;

    p {
      font-family: "Muli";
      font-size: 30px;
      font-weight: 800;
      color: #fff;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .panel-nav .nav-link.active {
    svg {
      fill: white;
    }
  }
}

.modal-lg,
.modal-xl {
  .modal-body {
    padding: 40px;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      padding: 25px;
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
  margin-bottom: 15px;
  margin-top: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: $radivision-blue;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: -12px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  cursor: pointer;
}

/* All the same stuff for Firefox */

input[type="range"]::-moz-range-thumb {
  background: $radivision-blue;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

/* All the same stuff for IE */

input[type="range"]::-ms-thumb {
  background: $radivision-blue;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

#editProfileModal .checkbox + label span:before {
  border: 1px solid #ced4da;
}

.content .tab-content .tab-pane.active.show {
  .nav.nav-tabs.panel-nav {
    margin-top: -15px;
    margin-bottom: 30px;
    padding: 0 50px;
  }

  .panel-nav .nav-link.active {
    color: white;
    border-bottom: 3px solid #4cb4e7;

    svg {
      fill: white;
    }
  }

  // .tab-pane.fade:not(.show) {
  //   display: none;
  // }
}

textarea.form-control {
  height: 100px;
}

.adjustResponsive {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}
