/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-10-09
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.message-modal {
  padding: 40px;
  text-align: center;
  width: 100%;

  h2 {
    text-transform: capitalize;
    color: $radivision-blue;
  }

  h4 {
    text-transform: capitalize;
  }

  p {
    font-size: 15pt;
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
  }
}
