.notify-me-modal {
  .modal-header {
    position: relative;
    border-bottom: 1px solid #2b2b2b;
    padding: 20px 20px 10px 40px;
    display: flex;
    justify-content: center;
    p {
      font-size: 26px;
    }
    svg {
      position: absolute;
      top: 25px;
      right: 25px;
      width: 30px;
      height: 30px;
    }
  }
  .modal-body {
    padding: 20px 40px;
    justify-content:center;
    align-items: center;
    flex-flow: column;
    display: flex;
    .content {
      width: 80%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      p {
        font-size: 18px;
        padding-bottom: 20px;
        text-align: left;
      }
    }
  }
  .modal-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    button {
      height: 40px;
      max-height: 40px;
      padding:0px 60px;
      text-transform: capitalize;
    }
  }
}
.notify-me-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  flex:1;
  &.centered {
    align-items: center;
  }
  button.btn-radivision {
    padding: 5px 30px;
    margin:0px;
    font-size: 18px;
    text-transform: capitalize;
    max-width: 160px;
    align-self: center;
  }
  div.already-subscribed {
    margin-bottom: 20px;
    font-size: 14px;
  }
  input.notify-me-email-address, input.notify-me-text-input {
    border: 1px solid #2e2e2e;
    background-color: #2e2e2e;
    border-radius: 22px;
    font-family: "Muli";
    width: 100%;
    padding: 10px 30px;
    margin: 10px 0px;
    font-size: 16px;
    color: white;
    height: 43px;
    &.has-error {
      border-color: red;
    }
  }

  .checkbox-control {
    margin-top: 20px;
  }
  .error-message {
    color: #e00;
    height: 30px;
    text-align: left;
    font-size: 14px;
  }
}
