.add-org-portfolio {
  // @media (min-width: 2000px) {
  //   max-width: 1600px !important;
  // }

  // .modal-dialog.modal-dialog-centered {
  //   @media (min-width: 2000px) {
  //     max-width: 1600px !important;
  //   }
  // }

  // div .modal-header p {
  //   color: #fafafa;
  //   font-size: 12pt;
  //   font-weight: 400;
  //   margin: 4px auto;
  //   width: 100%;
  //   text-align: center;
  // }

  .modal-body {
    // background-color: #fff;
    overflow: hidden;

    .nav.nav-tabs.panel-nav,
    .nav.dropdown {
      margin-top: 0px;
      position: relative;
      margin-left: -35px;
      margin-right: -35px;
      display: flex;
      width: calc(100% + 70px);
      background-color: #fff;
      border-bottom: 0px;
      margin-bottom: 35px;

      li.nav-item {
        flex: 1;
        border-bottom: 4px solid #c7c7c7;
        padding: 14px 0;

        a.nav-link {
          line-height: 26px;
          font-size: 16px;
          font-family: "Muli", sans-serif;
          font-weight: 700;
          color: #9fa1b1;
          width: 100%;
          border-right: 1px solid #9fa1b1;
          text-decoration: none;
          cursor: pointer;
          border-radius: 0px;
          padding: 0;
          text-transform: capitalize !important;
          margin: 0;

          &.active {
            border-bottom: none;
          }
        }

        &:last-of-type {
          a {
            border-right: none;
          }
        }

        &.done,
        &.active {
          border-bottom-color: $radivision-blue;

          a.nav-link {
            color: #32323b;
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
      }
    }

    &>.content {
      overflow: auto;
      overflow-x: hidden;
      // padding-right: 40px;
      // margin-right: -35px;
      // padding-right: 15px;
      max-height: calc(100vh - 300px);

      .org-portfolio-lists {
        position: relative;
        // padding-right: 20px;
      }

      .modal-body .content {
        overflow: auto;
        overflow-x: hidden;
        // padding-right: 41px;
        // margin-left: -41px;
        // padding-left: 41px;
      }

      @media (min-width: 2000px) {
        // padding-right: 70px;

        .modal-body .content {
          overflow: auto;
          overflow-x: hidden;
          // padding-right: 71px;
          // margin-left: -71px;
          // padding-left: 71px;
        }
      }
    }

    h3.section-title {
      font-weight: 900;
      text-transform: uppercase;
      // letter-spacing: 0.8px;
      // font-family: "MuliBlack";

      margin-bottom: 25px;
      color: #9fa1b1;
      cursor: not-allowed;
      letter-spacing: 0.075rem;
    }

    .org-portfolio-lists {
      h2 {
        font-weight: 900;
        text-transform: none;
        letter-spacing: 0.075rem;
        // letter-spacing: 0.8px;
        font-family: "MuliBold";
        margin-bottom: 25px;
        // margin-top: 35px;
        color: white;
        display: flex;
        align-items: center;
        font-size: 1.5rem;

        @media (min-width: 2000px) {
          font-size: 2rem !important;
          // margin-top: 70px;
          margin-bottom: 50px;

          span {
            font-size: 2rem;
          }
        }

        .alert {
          flex: 1;
          margin-left: 14px;

          &>span:first-child {
            flex: 1;
            text-align: center;
            color: #721c24;
            text-transform: none;
            font-size: 13px;
            line-height: 12px;
          }

          .dismiss-alert {
            svg {
              height: 14px;
              margin: 0;
            }
          }
        }

        svg {
          height: 24px;
          width: auto;
          margin-right: 15px;
          opacity: 0.5;
          transition: all 0.3s;
          fill: #8b8b8b;

          @media (min-width: 2000px) {
            height: 32px;
            margin-right: 30px;
          }
        }

        &.goBack {
          cursor: pointer;

          &:hover {
            svg {
              opacity: 1;
            }
          }
        }
      }

      ul.portfolio-lists {
        padding: 0;
        margin: 0;
        list-style: none;

        &.two-cols {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          margin-top: -140px;

          @media (min-width: 2000px) {
            margin-top: -220px;
          }

          &.empty {
            margin-top: 0;
          }

          li:first-child {
            margin-left: calc(50% + 15px);

            @include media-breakpoint-down(sm) {
              margin-left: 0;
            }
          }

          @include media-breakpoint-down(sm) {
            margin-top: 0;
          }
        }

        li {
          display: flex;
          align-items: center;
          border: 0px solid #2e2e2e;
          border-radius: 30px;
          line-height: 35px;
          padding: 10px 12px;
          padding-left: 25px;
          font-size: 20px;
          font-weight: 400;
          background-color: #2e2e2e;
          // box-shadow: rgba(0,0,0,0.4) 0px 1px 2px 0px;
          margin-bottom: 20px;

          @media (min-width: 2000px) {
            font-size: 32px;
            line-height: 48px;
            padding: 16px 20px;
            padding-left: 40px;
            margin-bottom: 20px;
          }

          &.invalid {
            background-color: rgb(255, 226, 226);
          }

          .drag {
            cursor: move;
            margin-right: 20px;
            margin-left: 15px;

            svg {
              width: auto;
              height: 22.5px;

              @media (min-width: 2000px) {
                height: 40px !important;
              }
            }
          }

          span.list-title {
            flex: 1;
            padding-right: 5px;

            @media (min-width: 2000px) {
              font-size: 32px;
            }

            input {
              width: 100%;
              padding: 0 10px;
              margin: 0;
              background-color: transparent;
              border: none;
              color: white;
              font-size: 20px;
              font-weight: 400;
              border-radius: 4px;
              transition: all 0.3s;

              @media (min-width: 2000px) {
                font-size: 32px;
                padding: 5px 20px;
              }

              &:placeholder {
                color: rgb(255, 255, 255, .25);
              }
            }
          }

          .controls {
            background-color: #8b8b8b !important;
            background-image: none;
            border-color: #d1d1db !important;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            margin-left: 5px;

            &.plus {
              background-color: transparent !important;
            }
            @media (min-width: 2000px) {
              width: 60px;
              height: 60px;
              margin-left: 10px;
            }

            span {
              cursor: pointer;
              // padding: 3px 8px;
              margin-right: 2px;
              border-radius: 50%;
              transition: all 0.3s;
              width: 40px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;

              &:last-child {
                margin: 0 auto;
              }

              @media (min-width: 2000px) {
                width: 60px;
              }

              svg {
                height: 14px;
                width: 14px;
                fill: #fff;
                vertical-align: middle;

                @media (min-width: 2000px) {
                  width: 28px;
                  height: 28px;
                }
              }

              &:hover {
                background-color: $radivision-blue;

                &.delete {
                  background-color: #dc3545;
                }

                svg {
                  fill: #fff;
                }
              }
            }
          }

          .plus {
            background-color: #8b8b8b !important;
            margin-right: 5px;

            svg {
              fill: #999999 !important;
              width: 16px!important;
              height: auto!important;
            }

            span:hover {
              background: none;
              cursor: default;
            }
          }

          &.list-item {
            position: relative;
            line-height: normal;
            padding: 0;
            font-size: 18px;
            width: calc(50% - 15px);
            margin-right: 30px;
            height: 120px;

            @media (min-width: 2000px) {
              height: 200px;
            }

            &:nth-child(2n + 1) {
              margin-right: 0;
            }

            @include media-breakpoint-down(sm) {
              width: 100%;
              margin-right: 0;
            }

            img {
              width: 120px;
              height: 120px;
              margin-right: 12px;
              // padding: 2px;
              border-radius: 10px;
              border: 0px solid #ced4da;
              border-left: 0;
              padding: 1px;

              @media (min-width: 2000px) {
                height: 200px;
                width: 200px;
              }
            }

            .controls:not(.drag) {
              position: absolute;
              top: 8px;
              right: 8px;
              display: flex;
              align-items: center;
              background-color: transparent !important;
              background-image: none;
              border-color: transparent !important;
              width: auto;
              height: auto;

              @media (min-width: 2000px) {
                top: 15px;
                right: 15px;
              }

              .listItemDrag,
              .listItemDelete {
                background-color: #8b8b8b !important;
                background-image: none;
                border-color: #8b8b8b !important;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: block;
                /* margin-left: -5px; */
                padding: 6px;
                text-align: center;

                @media (min-width: 2000px) {
                  width: 60px;
                  height: 60px;
                  padding: 12px;
                }
              }

              .listItemDelete:hover {
                background-color: #dc3545 !important;
              }

              .drag {
                margin-right: 5px;

                svg {
                  height: 16px;
                  // margin-top: -2px;
                  vertical-align: middle;
                  fill: #fff;

                  path {
                    fill: #fff;
                  }

                  @media (min-width: 2000px) {
                    height: 32px !important;
                  }
                }
              }
            }
          }
        }
      }

      .add-list {
        ul.portfolio-lists {
          padding-right: 0;

          &.left {
            padding-right: 12px;
          }

          li {
            .drag {
              cursor: default;

              span {
                cursor: default;
                // background-color:#8b8b8b;
                padding: 6px;
                width: inherit;
                height: inherit;
                margin: 0 auto;

                // svg {
                //   fill: #fff;
                // }

                // &:hover {
                //   background-color: rgb(153, 153, 153);
                // }
              }
            }

            &.list-item {
              cursor: pointer;
              justify-content: center;
              width: calc(50% - 6px);

              span.list-title {
                flex: none;
              }
            }
            button.btn-radivision {
              white-space: nowrap;
              padding:5px 15px;
              font-size: 14px;
              display: flex;
              justify-content: center;
              margin: 0px;
              svg {
                fill: white;
                width: 26px;
              }
            }
          }
        }

        &.floated {
          position: absolute;
          width: 50%;
          right: 3px;
          margin-top: -132px;

          @media (min-width: 2000px) {
            margin-top: -220px;
          }

          ul.portfolio-lists {
            li {
              &.list-item {
                width: calc(100% - 6px);
              }
            }
          }
        }

        &.two-cols {
          width: calc(50% - 15px);
          position: relative;
          z-index: 1;

          @include media-breakpoint-down(sm) {
            width: 100%;
          }

          ul.portfolio-lists {
            li {
              &.list-item {
                width: 100%;

                @include media-breakpoint-down(sm) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .orgAddEntity {
      margin: 0 -40px;

      // @media (min-width: 2000px) {
      //   margin: 0 -71px;
      // }

      .background-section {
        margin: 20px 40px;

        @media (min-width: 2000px) {
          margin: 0 71px;
        }
      }
    }

    .add-entity {
      margin: 0px !important;
    }
  }
}

.portfolio-lists.two-cols {
  span.list-title {
    padding-top: 20px;
  }
}

.overview-tab {
  .companies-entities-section {
    .story-icon svg {
      display: none;
    }

    .six-by-one-grid .grid-item .grid-details .story-icon:not(:empty)~* {
      margin-left: 0px;
    }
  }
}
