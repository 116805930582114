/**
* @author: Adham El Banhawy
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2018-09-18
* @filename: file-upload-modal.scss
*/

.file-upload-modal {
  .modal-content {
    height: 100%;

    .modal-body,
    .custom-file {
      height: 100%;
    }
  }

  h1 {
    font-size: 1.3rem;
  }

  .file-upload-drop {
    position: relative;
    width: inherit;
    height: 100%;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
    margin-bottom: 1em;

    h2 {
      font-size: 1em;
      text-align: center;
    }

    li {
      list-style: none;
      margin-left: 1em;
    }
  }
}